<template>
    <div>
        <div class="panel mt-6 p-3">
          <div class="pb-2 panel-body p-3">
            <div class="mb-4 flex justify-between flex-wrap justify-xs-center">
              <SelectComponent
                :items="this.item_quizes"
                :placeholder="false"
                return-type="object"
                @getObject="getObject"
                class="w-64 m-1" />
              <SearchBox
                placeholder="Search"
                class="w-74 p-2 m-1"
                v-model="keyword"
                result-key="branchName"
                />
            </div>
          </div>
          <Table :items="childItems" @onSelect="onSelect" hover-action>
            <template
              slot="row"
              slot-scope="{ item, index }"
            >
              <md-table-cell md-label="SL" class="font-bold">{{ index }}</md-table-cell>
              <md-table-cell md-sort-by="Quiz_id" md-label="Q_ID" class="font-bold">{{ item.Quiz_id }}</md-table-cell>
              <md-table-cell md-sort-by="title" md-label="QUESTION TITLE" class="font-bold">{{ item.name }}</md-table-cell>
              <md-table-cell md-label="NO OF QUESTION" class="pl-4 font-bold" >{{ item.total_quiz_no}}</md-table-cell>
              <md-table-cell md-label="QUIZ TYPE" class="font-bold">{{ item.template_type }}</md-table-cell>
              <md-table-cell md-label="Created At" class="font-bold">{{ item.created_at }}</md-table-cell>
              <md-table-cell md-label="Created By" class="text-algal font-bold">{{ item.created_by }}</md-table-cell>

              <md-table-cell md-label="STATUS">
               <span 
               class="font-bold" 
               :class="item.status !== 1 ? 'text-gray-700' : 'text-success' ">
                {{item.status !== 1 ? 'Draft' : 'Published'}} 
              </span>
              
              </md-table-cell>
              <md-table-cell>
                <span class="action flex">
                  <md-icon class="bg-link-water text-gray-700 rounded-full p-2 text-sm m-1">visibility</md-icon>
                  <md-icon class="bg-victoria text-white rounded-full p-2 text-sm m-1">edit</md-icon>
                  <md-icon class="bg-success text-white rounded-full p-2 text-sm m-1">send</md-icon>
                  <md-icon class="bg-tahiti-gold text-white rounded-full p-2 text-sm m-1">settings_ethernet</md-icon>
                  
                </span>
              </md-table-cell>
            </template>
          </Table>
          <Dialog>
            <component :is="component" :Quiz_item="this.Quiz_item"></component>
          </Dialog>
      </div>
    </div>
  </template>
  
  <script>
  import { SearchBox, SelectComponent ,Table, Dialog } from "@/components";
  import QuizAlert from "@/views/student/quiz-test/Alert.vue";
  import data from "@/data/quiz-test/data";
  import { mapMutations, mapGetters, mapActions } from "vuex";
  import { query } from "@/utils/queryString";
  
  export default {
    data() {
      return {
        quizzes: data.quizzes,
        amount: 10,
        Quiz_item: null,
        keyword: '',
        component: 'QuizAlert',
        item_quizes: [
          { id: 1, name: "ALL QUIZES" },
          { id: 2, name: "Item 02" },
          { id: 3, name: "Item 03" },
          { id: 4, name: "Item 04" }
        ]
      };
    },
    props: {
        activeItem: {
        type: String,
        },
        childItems: {
        type: Array
        }
    },
    components: {
      Table,
      SearchBox,
      SelectComponent,
      Dialog,
      QuizAlert,
    },
    watch: {
      keyword(oldvalue, newValue) {
          query.set('search', oldvalue)
          // For reseting search result
          if(oldvalue.length < newValue.length && oldvalue.length == 0) {
            this.actQuizSetList(`?${query.get()}`);
          }
          // For submitting search api after 3 cherecter
          if(oldvalue.length >= 3) {
            this.actQuizSetList(`?${query.get()}`);
          }
      }
    },
    computed: {
      ...mapGetters({
        getQuizs: "quiz/getQuizs",
        getQuizSetId: "quiz/getQuizSetId",
        getQuizData: "quiz/getQuizData",
        meta: "quiz/getQuizListMeta"
      })
    },
    methods: {
      ...mapMutations({
        dialog: "setShowDialog",
      }),
      ...mapActions({
        actQuizSetList: "quiz/actQuizSetList",
        actShowQuizs: "quiz/actShowQuizs",
        actGetQuizSetId: "quiz/actGetQuizSetId",
      }),
      submitSearchKeyword() {
        this.actQuizSetList(`?${query.get('search', this.keyword)}`);
      },
      onSelect(item) {
        console.log(item)
        this.component = 'QuizAlert';
  
        this.dialog(true);
        this.Quiz_item = item;
      },
      onStart(value) {
        this.selectedValue = value;
        if(this.selectedValue == '') {
          query.remove('page');
        }else {
          query.set('page', this.selectedValue);
        }
        this.actQuizSetList(`?${query.get()}`);
      },
      onEnd(value) {
        this.selectedValue = value;
        if(this.selectedValue == '') {
          query.remove('page');
        }else {
          query.set('page', this.selectedValue);
        }
        this.actQuizSetList(`?${query.get()}`);
      },
      searchResults(){
  
      }
    },
    mounted(){
       this.dialog(false);
    },
    created() {
      this.actQuizSetList(``); // pass emtpy query value for getting all data
    },
  };
  </script>
  
  <style></style>
  